<template>
	<div :class="themeClass" v-loading="getDelaystatus" class="min-width1100 max-width1100 margin-lr-auto" style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;">
		<!-- 面包屑 -->
		<!-- <breadcrumb></breadcrumb> -->

		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item v-for="(item,index) in breadList" :key="index" :to="{ path: item.path }">
				{{item.meta.title}}
			</el-breadcrumb-item>
		</el-breadcrumb>

		<!-- 面包屑END -->
		<!-- <div style="height:47px;background: #FFFFFF;width: 100%;margin-top: 5px;" class="border-F2F2F2 flex-row-space-between-center"> -->
			<!-- <div class="flex-row-align-center">
				<div class="margin-l-15">{{orderDetails.fBeginDate | formatDate}}</div>

				<div class="margin-l-13 flex-row-align-center">
					订单号：
					<p class="color-theme">{{ orderDetails.fOrderNumber }}</p>
				</div>
				<div style="margin-left: 28px;">
					<i class="iconfont icon-dianpu4 color-theme"></i>
					{{ orderDetails.fShopName }}
				</div>
				<div class="margin-l-13 flex-row-align-center">
					店铺电话：
					<p>{{ orderDetails.fOfficePhone }}</p> 
				</div>
				
			</div> -->
			<!-- <div class="flex-row-align-center margin-r-15"> -->
				<!-- TODO 待定 -->
				<!-- <div class="border-theme color-theme  pointer buttonStyle margin-r-10">查看收货码</div> -->
				<!-- <div v-if="orderDetails.fPlyingStatus != 0" @click="jumpOrderSendCarDetail" class="border-theme color-theme  pointer buttonStyle">派车详情</div> -->
			<!-- </div> -->
		<!-- </div> -->
		<div class="margin-t-10">
			<div class="padding-t-10" style="background: #f8f9fe;">
				<div class="flex-row-align-center">
          <div class="border-F2F2F2  margin-r-10 margin-l-10" style="width: 258px;height: 182px;border-radius:10px;background: #fff;">
            <div class="flex-row margin-t-25 margin-l-15 font-size13">订单号：<div style="color:#0173FE">{{ orderDetails.fOrderNumber }}</div></div>
            <div class="flex-row margin-t-15 margin-l-15 font-size14 flex-row-align-center">
				   	<i class="iconfont icon-dianpu4 color-theme padding-r-10"></i>
				   	{{ orderDetails.fShopName }}
				    </div>
            <div class="flex-row margin-t-15 margin-l-15 font-size13">店铺电话：<div >{{ orderDetails.fOfficePhone }}</div></div>
            <div class="flex-row margin-t-15 margin-l-15 font-size13">购买时间：<div >{{ orderDetails.fBeginDate | formatDate }}</div></div>
          </div>
					<div class="border-F2F2F2 " style="width: 612px;height: 182px;background: #fff;border-radius:10px;">
						<div class="font-size14  margin-b-10 margin-t-10 margin-l-15">收货信息：</div>
						<div class=" margin-l-15">
							<div class="price-box active  flex-row-space-between-center ">
								<div class="font-size14  flex-column">
									<div class="flex-row-align-center">
										收件人：
										<div class="margin-r-10">{{ orderDetails.fConsignee }}</div>
										<div>{{ orderDetails.fConsigneePhone }}</div>
									</div>
									<div class="  margin-t-10">工程：{{ orderDetails.fProjectName == "" ? "无" : orderDetails.fProjectName }}</div>
									<div class=" margin-t-10 ">邮寄地址：{{ orderDetails.fDeliveryAddress }}</div>
								</div>
							</div>
							<!-- 发票信息 -->
							<div class="margin-r-15  margin-t-20">
								<div class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;">
									<div class="flex-row-align-center">
										<i class="el-icon-s-ticket font-size18"></i>
										<p class="font-size14" style="padding-left: 6px;">发票信息</p>
										<!-- <p class="font-size14">(发送至{{invoiceInformation.feMail}})</p> -->
									</div>
									<el-tooltip :offset="10" placement="bottom-end" effect="light" popper-class="atooltip">
										<i class="el-icon-arrow-down font-size18 pointer"></i>
										<div slot="content">
											<div class="font-size12 font-color-666 bg-fff" style="padding: 0;line-height: 22px;">
												<div>
													<div>发票类型：{{invoiceInformation.fInvoiceClassName}}</div>
													<div>发票抬头：{{invoiceInformation.fUnitName}}</div>
													<div>企业税号：{{invoiceInformation.fTaxAccounts}}</div>
													<div>注册地址：{{invoiceInformation.fAddress}}</div>
													<div>公司电话：{{invoiceInformation.fPhone}}</div>
													<div>开户银行：{{invoiceInformation.fBankNames}}</div>
													<div class="margin-b-10 padding-b-10 border-bottom-F2F2F2">银行账号：{{invoiceInformation.fBankAccounts}}</div>
													<div>邮寄地址：{{invoiceInformation.fFullPathAddress}}</div>
													<div>收件人：{{invoiceInformation.fLinkman}} {{invoiceInformation.fLinkPhone}}</div>
													<div>邮箱：{{invoiceInformation.feMail}}</div>
												</div>
											</div>
										</div>
										<!-- <el-button>Top center</el-button> -->
									</el-tooltip>
								</div>
							</div>
							<!-- 发票信息结束 -->
						</div>
					</div>
          <!-- 收货人信息结束 -->
          <div div class="border-F2F2F2 margin-l-10 margin-r-10" style="width: 190px;height: 182px;background:#0173FE;border-radius:10px">
						<div class="font-size14  margin-b-10 margin-t-10 margin-l-15 font-color-fff" >订单状态：</div>
						<div class="flex-column-center-center font-size22 font-weight700 color-theme font-color-fff" v-if="orderDetails.fTradingModelID == '3'">
							<div class="font-color-fff margin-t-10">已付积分</div>
							<div class="font-color-fff font-size30 margin-t-10">¥{{ orderDetails.fUsePoint }}</div>
						</div>
						<div class="flex-column-center-center font-size22 font-weight700 color-theme font-color-fff" v-else>
							<div class="font-color-fff margin-t-10">{{ orderDetails.fPlyingStatusName }}</div>
							<div v-if="getUserInfo.fShowPrice == 1" class="font-color-fff font-size30 margin-t-10">¥{{ orderDetails.fMoney }}</div>
						</div>
					</div>
					<!-- 收货人信息 -->
				</div>
				<div class="margin-t-10 border-F2F2F2"  style="background: #fff;margin:10px;margin-bottom:0px;border-radius:10px">
					<div class="flex-row-space-between-center font-size14" style="height: 35px;background: #fff;border-radius:10px">
						<div style="margin-left: 380px;">商品</div>
						<div class="flex-row-space-between-center">
							<p class="width156" style="text-align: center;">数量</p>
							<p class="width156" style="text-align: center;">单价</p>
						</div>
					</div>
					<div class="wrap " >
						<div class=" ">
							<div class="">
								<div class="flex-row-space-between-center" style="height: 123px; background: #fff;">
									<div class="flex-row-start-start">
										<div class="flex-row-space-between-center" style="margin-left: 15px;">
											<div class="box-shadow-all imageRadius" style="">
												<el-image class="imageRadius" :src="orderDetails.imageUrl"></el-image>
											</div>
										</div>
										<div style="margin-left: 19px;">
											<div style="color:#333333;font-size:14px">{{ orderDetails.fGoodsFullName }}</div>
											<div class="flex-row-align-center margin-tb-10">
												<!-- 限时优惠 或 几倍积分 -->
												<!-- <div class="tag-box-red  font-size12 " v-if="subItem.text">{{ subItem.text }}</div> -->
												<div v-if="orderDetails.fTradingModelID=='2'" class="label font-color-fff text-center bg-40C6A9 margin-r-5">
													企业购
												</div>
												<div v-if="orderDetails.fTradingModelID==3" class="label-bg font-color-fff text-center bg-40C6A9 margin-r-5">
													{{orderDetails.fTradingModel}}
												</div>
												<div  v-if="orderDetails.fShopTypeID==1" class="label-bg font-color-fff text-center bg-40C6A9 margin-r-5">
													{{orderDetails.fShopTypeName}}
												</div>
												<div class="tag-box-blue color-theme border-theme font-size12">{{ orderDetails.fDelivery }}</div>
											</div>
											<div style="color:#666666;font-size:13px">工程：{{ orderDetails.fProjectName == "" ? "无" : orderDetails.fProjectName }}</div>
										</div>
									</div>
									<div class=" ">
										<div class="flex-row">
											<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
												<p class="font-color-666">X{{ orderDetails.fPlanAmount }}{{ orderDetails.fUnitName  }}</p>
											</div>
											<div class="flex-column-align-center  width156" style="border-left: 1px solid #eaebef;height: 65px;" v-if="orderDetails.fTradingModelID == '3'">
												<div class="font-weight700">{{ orderDetails.fUsePoint }}积分</div>
											</div>
											<div class="flex-column-align-center  width156" style="border-left: 1px solid #eaebef;height: 65px;" v-if="orderDetails.fTradingModelID!='3' && getUserInfo.fShowPrice == 1">
												<div style="color:#FF0000" v-if='orderDetails.fDeliveryID != 5'   class="font-weight700">￥{{ orderDetails.fGoodsPrice | NumFormat }}</div>
												<div style="color:#FF0000" v-if='orderDetails.fDeliveryID == 5' class="font-weight700">￥{{ orderDetails.fGoodsPrice + orderDetails.fTransportServicePrice + orderDetails.fcFreightPrice | NumFormat }}</div>
												<div style="color:#333333;margin-top:4px">（含运费：{{ orderDetails.ffMoneyReceivable | NumFormat }}）</div>
											</div>
											<div class="flex-column-align-center  width156" style="border-left: 1px solid #eaebef;height: 65px;" v-else>
												<div class="font-weight700">--</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="height: 50px;background-color: #FFF7EE;color:#FF0000;" class="margin-lr-15 flex-row-space-between-center font-size18">
								<div style="width: 25%;text-align: center;">订单量：{{ orderDetails.fPlanAmount }}</div>
								<div style="width: 25%;text-align: center;">已派车量：{{ orderDetails.fScheduleVanAmount }}</div>
                <div style="height:100%;width:2px;background-color:#fff"></div>
								<div style="width: 25%;text-align: center;">提货量：{{ orderDetails.fReceivedAmount }}</div>
								<div style="width: 25%;text-align: center;">可派车量：{{ orderDetails.sendCarAmount }}</div>
							</div>


							<div class="flex-row-space-between-center margin-lr-15 margin-t-15 bg-FAFAFA" style="height: 53px;width: 97%;background: #fff;">
								<div class="margin-l-15 flex-row">仓库地址：<div style="color:#999999;font-size:13px">{{ orderDetails.fAddress }}</div></div>
                <div style="color:#999999">
											<a :href="'https://map.qq.com/?what=' + orderDetails.fAddress + '&=&ref=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'"
											 target="view_blank" style="color:#999999">地图</a>

											<i class="el-icon-arrow-right"></i>
										</div>
							</div>
							<!-- 本地页面展示地图  start -->
							<!-- <div class="flex-column-justify-center margin-lr-15 margin-t-15 bg-FAFAFA" style="height: 53px;">
								<div class="margin-l-15">仓库地址：</div>
								<div class="flex-row-space-between-center margin-lr-15">
									<div>{{ orderDetails.fDeliveryAddress }}</div>
									<div class="map pointer flex-row">
										<div style="" @click="showMap">
											<a>本页面地图</a>
											<i class="el-icon-arrow-right"></i>
										</div>
									</div>
								</div>
							</div>
							<div id="container" style="height: 200px;" v-show="mapShow"></div> -->
							<!-- 本地页面展示地图  end -->
						</div>
					</div>
				</div>
         <!-- 限时活动奖励 -->
         <div class="express" v-show="tableData.length>0">
          <div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 tit">{{orderDetails.fTradingModelID === '2' ? '订单监控' : '定向送达活动'}}</div>
          <!-- <div class="btn" @click="showActivity(index)">详情 ></div> -->
          <el-table
            v-show="tableData.length>0  && orderDetails.fTradingModelID === '1'"
            :header-cell-style="{background:'#F8F9FE'}"
            class="margin-b-10 margin-t-10 margin-lr-15"
            :data="tableData"
            size="mini"
            border
            style="width: 1070px">
            <el-table-column
              align="left"
              label="送达目的地"
              width="150">
              <template slot-scope="scope">
                <el-button type="text" class="fence" @click="handleClick(scope.row)">{{scope.row.fFence}}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="fDelivery"
              label="提货方式"
              width="160">
            </el-table-column>
            <el-table-column
              align="center"
              label="运输时长(分钟)"
              width="200">
             <template slot-scope="scope">
               <template v-if="scope.row.fMaxMins !=0">
             	  <span>{{scope.row.fMaxMins}}</span>
             	  	<hr>
             	  	<span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
             	  </template>
             	  <template v-if="scope.row.fMaxMins ==0">
             	  	<span>不限时间</span>
             	  </template>		
             </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="活动时间">
              <template slot-scope="scope">
                <span>{{scope.row.fBeginTime}}</span>
                <hr>
                <span>至</span>
                <hr>
                <span>{{scope.row.fEndTime}}</span>
                <hr>
                <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="fPointAmount"
              label="赠送积分"
              width="120">
            </el-table-column>
			<el-table-column
			  align="center"
			  prop="fIsHdName"
			  label="设备"
			  width="100">
			</el-table-column>
            <el-table-column
              label=""
              width="160"
              align="center">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="handleClick(scope.row)"
                  type="primary"
                  size="small">
                  查看目的地范围
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="tableData.length>0 && orderDetails.fTradingModelID === '2'"
            :header-cell-style="{background:'#F8F9FE'}"
            class="margin-b-10 margin-t-10 margin-lr-15"
            :data="tableData"
            size="mini"
            border
            style="width: 1070px">
            <el-table-column
              align="left"
              label="送达目的地">
              <template slot-scope="scope">
                <el-button type="text" class="fence" @click="handleClick(scope.row)">{{scope.row.fFence}}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="fDelivery"
              label="提货方式">
            </el-table-column>
            <el-table-column
              align="center"
              label="运输时长(分钟)"
              width="250">
              <template slot-scope="scope">
                <span>{{scope.row.fMaxMins}}</span>
                <hr>
                <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
              </template>
            </el-table-column>
			<el-table-column
			  align="center"
			  prop="fIsHdName"
			  label="设备"
			  width="100">
			</el-table-column>
            <el-table-column
              label=""
              width="160"
              align="center">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="handleClick(scope.row)"
                  type="primary"
                  size="small">
                  查看目的地范围
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
			</div>
			<div class="padding-b-20 ">
				<div class="bottomBox margin-b-10" v-if="orderDetails.fPlyingStatus == 1 && JSON.stringify(paymentData) != '{}'">
					<div class="margin-l-15 padding-tb-10">
						<div class="font-size14 margin-b-10">支付信息：</div>
						<div class="font-size12" style="height: 44rpx;">
							<div class="font-color-999">{{paymentData.fTxSn==''?'支付单号：' + paymentData.fBillNumber:'支付流水：' + paymentData.fTxSn}}</div>
						</div>
						<div class="font-size12" style="height: 44rpx;">
							<!-- 支付状态 {{ orderDetails.fPlyingStatusName }} -->
							<div class="font-color-999">支付状态：{{paymentData.fPlyingStatusName}}-{{paymentData.fSettleMode}}</div>
						</div>
						<div class="font-size12  " style="height: 44rpx;">
							<div class="font-color-999">来源：{{paymentData.fAppType}}</div>
						</div>
						<div class="font-size12  " style="height: 44rpx;">
							<div class="font-color-999">最后更新时间：{{paymentData.fModifyTime}}</div>
						</div>
					</div>
				</div>
				<div class="margin-r-20 font-size14 bottomBox" v-if="orderDetails.fTradingModelID != '3'">
					<div class="padding-10 flex-column-start-end">
						<div v-if="getUserInfo.fShowPrice == 1">
							<div class="font-color-666 flex-row-space-between-center" style="width: 180px;">
								货款金额：
								<p v-if='orderDetails.fDeliveryID != 5'>¥{{ orderDetails.fgMoneyReceivable | NumFormat }}</p>
								<p v-if='orderDetails.fDeliveryID == 5'>¥{{ (orderDetails.fGoodsPrice + orderDetails.fTransportServicePrice + orderDetails.fcFreightPrice) *orderDetails.fPlanAmount | NumFormat }}</p>
							</div>
							<div    class="font-color-666 flex-row-space-between-center" style="width: 180px;">
								运费金额：
								<p  v-if='orderDetails.fDeliveryID != 5 && orderDetails.fDeliveryID != 2'>+ ¥{{ orderDetails.ffMoneyReceivable | NumFormat }}</p>
								<p  v-if='orderDetails.fDeliveryID == 5 || orderDetails.fDeliveryID == 2'>+ ¥{{ orderDetails.ffMoneyReceivable * 0 | NumFormat }}</p>
							</div>
							<div class="font-color-666 flex-row-space-between-center" style="width: 180px;">
								优惠券抵扣：
								<p>- ¥{{ orderDetails.fCouponMoney | NumFormat }}</p>
							</div>
							<div class="font-color-666 flex-row-space-between-center" style="width: 180px;">
								积分抵扣：
								<p>- ¥{{ orderDetails.fPointMoney | NumFormat }}</p>
							</div>
							<div class=" flex-row-space-between-center font-size16" style="width: 180px;margin-top:2px">
								实付金额：
								<p class="font-color-FF0000 font-weight700">¥{{ orderDetails.fGatheringMoney  | NumFormat }}</p>
							</div>
						</div>
						<div class="flex-row-align-center margin-t-20">
							<button v-if="orderDetails.fEnablePayStatus == 1 && orderDetails.fNegotiateStatus !=1" class="com-btn background-color-theme pointer" @click="toPayment(orderDetails)">去支付</button>
							<button v-if="orderDetails.fNegotiateStatus ==1" class="com-btn   margin-l-10" style="border: 1px solid #0173FE;color:#0173FE;background-color: white;">{{orderDetails.fNegotiateStatusName}}</button>
              <button v-if="orderDetails.fEnableSchedule == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="toSendCar(orderDetails)">去派车</button>
							<button v-if="orderDetails.fEnableReturnStatus == 1 && getUserInfo.fShowPrice == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="applyForRefund(orderDetails)">申请退款</button>
							<button v-if="orderDetails.fEnableDelayStatus == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="delay(orderDetails)" :disabled="getDelaystatus">申请延期</button>
							<button v-if="orderDetails.fEnableEvaluateStatus == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="toEvaluate(orderDetails)">评价</button>
               <div v-if="orderDetails.fPlyingStatus != 0" @click="jumpOrderSendCarDetail" class="border-theme color-theme  pointer buttonStyle">派车详情</div>
						</div>
					</div>
				</div>
				<div class="margin-r-20 font-size14 bottomBox" v-else>
					<div class="padding-10 flex-column-start-end">
						<div class="flex-row-align-center margin-t-10">
							<button v-if="orderDetails.fEnablePayStatus == 1" class="com-btn background-color-theme pointer" @click="toPayment(orderDetails)">去支付</button>
							<button v-if="orderDetails.fEnableSchedule == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="toSendCar(orderDetails)">去派车</button>
							<button v-if="orderDetails.fEnableReturnStatus == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="applyForRefund(orderDetails)">申请退款</button>
							<button v-if="orderDetails.fEnableDelayStatus == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="delay(orderDetails)" :disabled="getDelaystatus">申请延期</button>
							<button v-if="orderDetails.fEnableEvaluateStatus == 1" class="com-btn background-color-theme pointer margin-l-10"
							 @click="toEvaluate(orderDetails)">评价</button>
               <div v-if="orderDetails.fPlyingStatus != 0" @click="jumpOrderSendCarDetail" class="border-theme color-theme  pointer buttonStyle">派车详情</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <router-view></router-view> -->
		<!-- 收货地址 -->

    <!-- 地图 -->
     <myMapType v-if="myMapTypeVisible" :showModel="myMapTypeVisible" :myMapTypeID="myMapTypeID"  @change="myMapTypeChange"></myMapType>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
   import myMapType from '@/components/myMapType.vue';
	export default {
		components: {
			breadcrumb,
			myMapType
			// axios
		},
		data() {
			return {
				myMapTypeVisible:false, //控制地图显示弹窗的显示与隐藏
				myMapTypeID:"",		
				breadList: [],
				maplat: '',
				maplng: '',
				mapShow: true,
				tipsShow: false, //发票信息明细
				// 订单详情数据
				orderDetails: {},
				// 发票信息
				invoiceInformation: {},
				paymentData: {}, //支付流水呢块信息

        // 限时活动
        tableData: [],
        showFence: false,
        fUnitName: '',
        // 地图
        map: {},
        district: '',
        opts: {
          subdistrict: 0, // 获取边界不需要返回下级行政区
          extensions: 'all', // 返回行政区边界坐标组等具体信息
          level: 'district' // 查询行政级别为 市
        },
        showbiz: false,
        polygonData: [],
        getDelaystatus: false

			};
		},
		mounted() {

			let data = [{
				path: this.$route.path,
				meta: {
					title: this.$route.meta.title
				}
			}];
			console.log('this.$route',this.$route)
			if (this.$route.name == 'OrderDetails') {
				sessionStorage.setItem('itemPath', 'OrderDetails')
			}
			if (this.$route.query.detailsname == '全部订单') {
				sessionStorage.ApplicationName = this.$route.query.detailsname
			} else {
				sessionStorage.ApplicationName = this.$route.query.detailsname.slice(0, -2)
			}
			this.breadList = [{
				path: this.$route.query.detailspath,
				meta: {
					title: this.$route.query.detailsname
				}
			}].concat(data);
			this.getOrderDetails()
			this.getInvoiceInfo();
			this.getInvoiceAddress();
			// 获取支付流水
			this.getPaymentFlow();

		},
		// activated() {
		// 	this.getOrderDetails()
		// 	this.getInvoiceInfo();
		// 	this.getInvoiceAddress();
		// },
		created() {
			/* 异步创建JS */
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&callback=init';
			document.body.appendChild(script);

			/* 获取仓库地址的坐标 */
			// this.getMyAddress();
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			// 获取支付流水
			getPaymentFlow() {
				this.ApiRequestPostNOMess("api/mall/ebsale/customer-payment-bill/order-payment-info", {
					id: this.$route.query.id
				}).then(res => {
					res.obj.fModifyTime = this.getTime(res.obj.fModifyTime);
					this.paymentData = res.obj;
				}).catch(err => {

				})
			},
			// 跳转订单派车单详情
			jumpOrderSendCarDetail() {
				this.$store.commit("changeCommonData", {
					id: this.$route.query.id
				});
				this.$router.push({
					name: "OrderSendCarDetail",
					query: {
						id: this.$route.query.id
					}
				})
			},
			// 获取订单信息
			getOrderDetails() {
				let id = this.$store.getters.getCommonData.id;
				this.ApiRequestPostNOMess("api/mall/ebsale/order/get", {
					id: this.$route.query.id
				}).then(res => {
					res.obj.fDeliveryAddress = res.obj.fDeliveryAddress.replace(/\\/g, '');
					res.obj.fPlanAmount = res.obj.fPlanAmount.toFixed(2);
					res.obj.fScheduleVanAmount = res.obj.fScheduleVanAmount.toFixed(2);
					res.obj.fReceivedAmount = res.obj.fReceivedAmount.toFixed(2);
					res.obj.sendCarAmount = (res.obj.fPlanAmount - res.obj.fScheduleVanAmount).toFixed(2);
					this.orderDetails = res.obj

          // 获取订单规则传参
          this.fUnitName = res.obj.fUnitName
          if (res.obj.fTradingModelID === '1') {
            this.getOrderRule()
          } else {
            let orderId = this.$store.getters.getCommonData.id
            this.ApiRequestPostNOMess('api/mall/ebmonitor/monitor-order/get-by-orderId',{id:orderId}).then(res => {
              if (res.obj) {
                res.obj.fDelivery = this.orderDetails.fDelivery
                this.tableData = [res.obj]
              } else {
                this.tableData = []
              }
            })
          }

					this.getOrderPicture();
					// this.getMyAddress();
				}).catch(err => {

				})
			},
			//获取商品图片
			getOrderPicture() {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get', {
					id: this.orderDetails.fGoodsID
				}).then(
					res => {
						this.orderDetails.imageUrl = res.obj.fPicturePath;
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					},
					error => {}
				);
			},
			//获取发票信息
			getInvoiceInfo() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/invoice-info/get', {}).then(
					res => {
						this.invoiceInformation.fInvoiceClassName = res.obj.fInvoiceClassName;
						this.invoiceInformation.fUnitName = res.obj.fUnitName;
						this.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						this.invoiceInformation.fAddress = res.obj.fAddress.replace(/\\/g, '');
						this.invoiceInformation.fPhone = res.obj.fPhone;
						this.invoiceInformation.fBankNames = res.obj.fBankNames;
						this.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
					},
					error => {}
				);
			},
			//获取发票地址
			getInvoiceAddress() {
				this.ApiRequestPostNOMess('api/mall/eb-customer/address/get-invoice-address', {}).then(
					res => {
						this.invoiceInformation.feMail = res.obj.feMail;
						this.invoiceInformation.fLinkman = res.obj.fLinkman;
						this.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
						this.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
					},
					error => {}
				);
			},
			//去支付
			toPayment(item) {
				this.$store.commit("changeCommonData", [{
					id: item.fOrderID
				}]);
				if (item.fTradingModelID == "2") {
					this.$router.push({
						name: "FillOrderEditB2B",
						params: {}
					})
				} else {
					this.$router.push({
						name: "FillOrderEdit",
						params: {}
					})
				}
			},
			// 派车
			toSendCar(item) {
				// fOrderID  订单ID
				this.$router.push({
					name: "SendCar",
					query: {
						id: item.fOrderID
					}
				});
			},
			//申请退款
			applyForRefund(item) {
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/computer-refund-bill-by-order-id", {
					fOrderID: item.fOrderID,
					fAppTypeID: this.fAppTypeID
				}).then(res => {
					if (res.message == "") {
						this.$store.commit("changeCommonData", {
							id: item.fOrderID
						});
						this.$router.push({
							name: "RefundApplication",
							query: {
								id: item.fOrderID
							}
						})
					} else {
						this.tipsInfo(res.message)
					}
				})
			},
			//评价
			toEvaluate(item) {
				this.$router.push({
					name: "Evaluate",
					params: {
						id: item.fOrderID
					}
				})
			},
			// 延期
			delay(item) {
				this.$confirm('确定要延期此订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 延期调用API方法
          this.getDelaystatus = true
					this.ApiRequestPost("api/mall/ebsale/order-delay-bill/create", {
						fOrderID: item.fOrderID
					}).then(res => {
						this.$emit("queryList")
            this.getDelaystatus = false
					}).catch(err => {
						this.$emit("queryList")
            this.getDelaystatus = false
					})
				}).catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
			},
			showMap() {
				this.mapShow = !this.mapShow;
			},
			getMyAddress() {
				let _this = this;
				this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address=' + this.orderDetails.fDeliveryAddress +
						'&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&output=jsonp', {})
					.then(json => {
						// alert(JSON.stringify(json.result.location));
						_this.maplat = json.result.location.lat;
						_this.maplng = json.result.location.lng;
						/* 初始化地图,这里的1秒延迟是必要的,否则不能正常渲染出来 */
						setTimeout(function() {
							_this.initMap();
						}, 1000);
					})
					.catch(err => {
						alert(JSON.stringify(err));
					});
			},
			initMap() {
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				// "lat": 43.794399第一个参数
				// "lng": 87.631676,第二个参数
				var center = new TMap.LatLng(this.maplat, this.maplng); //设置中心点坐标
				//初始化地图
				var map = new TMap.Map(container, {
					center: center
				});
				/* 这个就是那个地图上的指针点 */
				//创建并初始化MultiMarker
				var markerLayer = new TMap.MultiMarker({
					map: map, //指定地图容器
					//样式定义
					styles: {
						//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
						myStyle: new TMap.MarkerStyle({
							width: 25, // 点标记样式宽度（像素）
							height: 35, // 点标记样式高度（像素）
							//src: '../img/marker.png', //自定义图片路径
							//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
							anchor: {
								x: 16,
								y: 32
							}
						})
					},
					//点标记数据数组
					geometries: [{
						id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
						styleId: 'myStyle', //指定样式id
						position: new TMap.LatLng(this.maplat, this.maplng), //点标记坐标位置
						properties: {
							//自定义属性
							title: 'marker1'
						}
					}]
				});
			},
			gotoOrder() {},

      // 获取订单的监控规则
      getOrderRule() {
        let orderId = this.$store.getters.getCommonData.id
        let that = this
        this.ApiRequestPostNOMess('api/mall/ebmonitor/monitor-order/get-by-orderId',{id:orderId}).then(res => {
          if (res.obj) {
            that.ApiRequestPostNOMess('api/mall/ebactivity/limited-delivery-rule/get',{id:res.obj.fRuleID}).then(result => {
              console.log(result.obj.fPointAmount)
              res.obj.activityTime = that.getTime(result.obj.fBeginTime)   + '至' + that.getTime(result.obj.fEndTime)
              res.obj.fBeginTime = that.getTime(result.obj.fBeginTime) 
              res.obj.fEndTime =  that.getTime(result.obj.fEndTime)
              res.obj.fPointAmount = result.obj.fPointAmount + '积分/' + this.fUnitName
              res.obj.fDelivery = result.obj.fDeliveryNames
			  res.obj.fIsHd= result.obj.fIsHd
			  res.obj.fAreaID =result.obj.fAreaID
			  res.obj.fFence=result.obj.fFence
              that.tableData = [res.obj]
			  that.tableData.map(item=>{
			  	if(item.fIsHd ==1){
			  	  item.fIsHdName='汉德设备'
			  	}else if(item.fIsHd ==0){
			  	  item.fIsHdName='GPS设备'
			  	} 	
			  })
            })
          } else {
            this.tableData = []
          }
          
        })
      },
	  // 新增弹窗提交成功回调
	  myMapTypeChange(val) {
	    this.myMapTypeVisible = val;
	  },
	  // 显示地图
	 handleClick(row) {
	    this.myMapTypeID= row.fAreaID
	    this.myMapTypeVisible=true;
	  },
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}


	.label {
		padding: 1px 5px;
	}

	.label-bg {
		// width: 56px;
		background: linear-gradient(to right, #ff0000, #ff9999);
		padding: 1px 5px;
	}

	.bg-40C6A9 {
		background-color: #40C6A9;
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	.com-btn {
		width: 160px;
		height: 40px;
    border-radius: 5px;
    font-size: 14px !important;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
    border-radius: 10px;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		width: 100%;
		background: #f8f9fe;
		line-height: 22px;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		// width: 890px;
		// height: 138px;
		// border: 1px solid #55aaff;
		// @include themify($themes) {
		// border: 2px solid themed('themes_color');
		// }
		// margin: 15px;
		// position: relative;
		// transition: all 0.5s ease;
	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;
		// border: 2px solid #dfdfdf;
	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-top-F2F2F2 .el-input__inner {
		border-radius: 0;
		width: 196px;
		height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 196px;
		height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #dcdfe6;
	}

	/* 虚线 */
	.divcss5-3 {
		border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
	}

	.buttonStyle {
    width: 158px;
    height: 38px;
    font-size: 13px;
		background-color: #ffffff;
		border-radius: 2px;
		display: flex;
		align-items: center;
		/*垂直居中*/
		justify-content: center;
		/*水平居中*/
    margin-left: 10px;
	}

	.flexCenterBox {
		display: flex;
		align-items: center;
		/*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	.map:hover {
		text-decoration: underline;
		color: blue;
	}
</style>

<style lang="scss">
  .express {
    border: 1px solid #f2f2f2;
    margin-top: 13px;
  }
  .myMap {
    .el-dialog__body {
      padding: 0;
      
    }
  }
  .tit {
    display: inline-block;
  }
  .btn {
    color: #0173FE;
    cursor: pointer;
    display: inline-block;
  }
	.atooltip {
		width: 600px;
		border-radius: 10px !important;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039) !important;
		border: 0px !important;
	}

	.el-tooltip__popper .popper__arrow {
		border: 0px;
	}
</style>
